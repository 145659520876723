<template>
    <div>
        <v-row>
            <v-col cols="12">
                <div class="d-flex justify-space-between">
                    <div style="padding: 18px 12px">
                        <div class="text-2xl"><i class="mdi mdi-account-key-outline"></i> ຈັດການຂໍ້ມູນຜູ້ໃຊ້ລະບົບ</div>
                    </div>
                    <v-breadcrumbs
                        class="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        :items="$route.meta.breadcrumb"
                        divider=">"
                        large
                    />

                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <v-btn color="green" outlined dark class="mx-1" @click="getAddData()" v-if="adddata">
                            <i class="mdi mdi-plus-circle-outline"></i> 
                            <span class="d-none d-md-block d-lg-block d-xl-block">ເພີ່ມຂໍ້ມູນລົດ</span>
                        </v-btn>  
                        <v-btn color="primary" outlined dark class="mx-1" @click="getListBranch()">
                            <i class="mdi mdi-reload"></i> 
                            <span class="d-none d-md-block d-lg-block d-xl-block">ຮີໂຫລດ</span>
                        </v-btn>       
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="ຄົ້ນຫາ"
                            single-line
                            hide-details
                            style="max-width: 200px"
                        />
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="listdata"
                        :search="search"
                        :items-per-page="10"
                        class="elevation-1"
                        :loading="$store.state.auth.loading2"
                        loading-text="ກຳລັງໂຫລດ...ກະລຸນາລໍຖ້າ"
                        item-key="br_id"
                        v-model="selected"
                        fixed-header
                        height="530px"
                    >
                        <template v-slot:item.option="{ item }">
                            <div class="whitespace-nowrap">
                                <v-btn
                                    color="primary"
                                    fab
                                    small
                                    dark
                                    text 
                                    v-if="item.v_edit"
                                    @click="getEditData(item)"
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <!-- <v-btn
                                    color="error"
                                    fab
                                    small
                                    dark
                                    text
                                    v-if="item.v_delete"
                                    @click="getDeleteData(item)"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn> -->
                            </div>
                        </template>
                        <template v-slot:item.br_status="{ item }">
                            <v-switch
                                v-model="item.is_active"
                                inset
                                color="success"
                                :label="(item.is_active) ? 'ເປີດປົກກະຕິ' : 'ປິດສາຂາ'"
                                @change="changeStatus(item)"
                            />
                        </template>
                        <!-- <template v-slot:item.br_status="{ item }">
                            <v-chip
                                :color="(item.is_active) ? 'success': 'error'"
                                dark
                            >
                                {{ (item.is_active) ? 'ເປີດປົກກະຕິ' : 'ປິດສາຂາ' }}
                            </v-chip>
                        </template> -->
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
            v-model="formDialog"
            persistent
            max-width="800px"
        >
            <v-card>
                <v-card-title class="amber lighten-1 black--text">
                    <span class="text-3xl"><i :class="mdicon"></i>{{mdtitle}}</span>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="valid" ref="formdata" class="pt-2" lazy-validation>
                        <div class="border rounded mt-4 p-4">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field 
                                        type="text" 
                                        label="ຊື່ລົດ" 
                                        placeholder="ຊື່ລົດ" 
                                        outlined  
                                        dense
                                        v-model="formManage.br_name_la" 
                                        :rules="formManageRules.br_name_la" 
                                        required 
                                        append-icon="mdi-truck-cargo-container"  
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field 
                                        type="text" 
                                        label="ເລກທະບຽນລົດ" 
                                        placeholder="ເລກທະບຽນລົດ" 
                                        outlined  
                                        dense
                                        v-model="formManage.br_tel" 
                                        :rules="formManageRules.br_name_la" 
                                        required 
                                        append-icon="mdi-truck-cargo-container"  
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        label="ລາຍລະອຽດລົດ" 
                                        placeholder="ລາຍລະອຽດລົດ" 
                                        outlined  
                                        v-model="formManage.br_address" 
                                    />
                                </v-col>
                                
                            </v-row>
                        </div>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-action>
                    <v-container>
                        <v-row justify="end" class="p-1">
                            <v-btn class="mx-1" color="success" @click="saveData()"><i class="mdi mdi-content-save-outline"></i> ບັນທຶກ</v-btn>
                            <v-btn class="mx-1" color="error" @click="formDialog = false"><i class="mdi mdi-close-circle-outline"></i> ກັບຄືນ</v-btn>
                        </v-row>
                    </v-container>
                </v-card-action>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                adddata: false,
                req_id: '',
                action: '',
                mdtitle: '',
                mdicon: '',
                valid: false,
                formDialog: false, 
                formManage: {
                    br_name_la: '',
                    br_name_en: '',
                    br_tel: '',
                    br_address: '',
                },
                formManageRules: {
                    br_name_la: [
                        v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ',
                    ],
                    br_name_en: [
                        v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ',
                    ],
                },
                headers: [
                    {
                        text: '#',
                        align: 'center',
                        sortable: false,
                        value: 'rno',
                        class: 'black--text title whitespace-nowrap'
                    },
                    {
                        text: 'ຊື່ລົດ',
                        align: 'start',
                        sortable: true,
                        value: 'br_name_la',
                        class: 'black--text title whitespace-nowrap'
                    },
                    {
                        text: 'ເລກທະບຽນລົດ',
                        align: 'start',
                        sortable: true,
                        value: 'br_name_en',
                        class: 'black--text title whitespace-nowrap'
                    },
                    {
                        text: 'ລາຍລະອຽດລົດ',
                        align: 'start',
                        sortable: true,
                        value: 'br_address',
                        class: 'black--text title whitespace-nowrap'
                    },
                    {
                        text: 'ຕົວເລືອກ',
                        align: 'center',
                        sortable: false,
                        value: 'option',
                        class: 'black--text title whitespace-nowrap'
                    },
                ],
                selected: [],
                search: "",
                listdata: [],
            }
        },
        async created() {
            await this.getListBranch();
        },
        methods: {
            async getListBranch(){
                const formdata = await {
                    action: "listbranch",
                    route: this.$route.name,
                    brcode: "",
                }
                this.action = await "add";
                this.listdata = await [];
                await this.$store.commit("auth/setLoading2", true);
                const result = await this.$store.dispatch("manage/getListBranch", formdata);
                await this.$store.commit("auth/setLoading2", false);
                if(result.success){
                    this.listdata = await result.data;
                    this.adddata = await result.data[0]['v_add'];
                }
            },
            async getAddData(){
                this.action = await 'add';
                this.formManage = await {
                    br_name_la: '',
                    br_name_en: '',
                    br_tel: '',
                    br_address: '',
                };
                this.req_id = '';
                this.mdtitle = await "ເພີ່ມຂໍ້ມູນລົດ";
                this.mdicon = await "mdi mdi-plus-circle-outline";
                if(this.valid){
                    await this.$refs.formdata.resetValidation();
                }
                this.formDialog = await true;
            },
            async getEditData(data){
                this.action = await 'edit';
                this.formManage = await {
                    br_name_la: data.br_name_la,
                    br_name_en: data.br_name_en,
                    br_tel: data.br_tel,
                    br_address: data.br_address,
                };
                this.req_id = data.br_code;
                this.mdtitle = await "ແກ້ໄຂຂໍ້ມູນລົດ";
                this.mdicon = await "mdi mdi-pencil";
                this.formDialog = await true;
            },
            async changeStatus(data){
                await this.$store.commit("auth/setLoading", true);
                const formdata = await {
                    action: 'changeStatus',
                    id: data.br_code,
                }
                const result = await this.$store.dispatch("manage/getChangeBranch", formdata);
                await this.$store.commit("auth/setLoading", false);
                if(!result.success){
                    this.$swal.fire("Oops",result.message,"warning");
                }
            },
            async saveData(){
                if(this.valid){
                    if(this.formManage.br_name_la == '' || this.formManage.br_name_en == ''){
                        this.$refs.formdata.validate();
                        return false
                    }else{
                        this.$swal.fire({
                            title: 'ບັນທຶກຂໍ້ມູນ',
                            text: 'ທ່ານຕ້ອງການບັນທຶກຂໍ້ມູນ ຫລື ບໍ່ ?',
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonColor: '#85ce61',
                            cancelButtonColor: '#F56C6C',
                            confirmButtonText: '<i class="mdi mdi-check-circle-outline"></i>  ຕົກລົງ',
                            cancelButtonText: '<i class="mdi mdi-close-circle-outline"></i>  ຍົກເລີກ'
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                await this.$store.commit("auth/setLoading", true);
                                var result;
                                if(this.action == 'add'){
                                    const formdata = await {
                                        action: 'addbranch',
                                        br_name_la: this.formManage.br_name_la,
                                        br_name_en: this.formManage.br_name_en,
                                        br_tel: this.formManage.br_tel,
                                        br_address: this.formManage.br_address,
                                    }
                                    result = await this.$store.dispatch("manage/getAddBranch", formdata);
                                }else{
                                    const formdata = await {
                                        action: 'editbranch',
                                        br_name_la: this.formManage.br_name_la,
                                        br_name_en: this.formManage.br_name_en,
                                        br_tel: this.formManage.br_tel,
                                        br_address: this.formManage.br_address,
                                        id: this.req_id,
                                    }
                                    result = await this.$store.dispatch("manage/getEditBranch", formdata);
                                }
                                await this.$store.commit("auth/setLoading", false);
                                if(result.success){
                                    this.formDialog = await false;
                                    await this.getListBranch();
                                    await this.$toast.fire(result.message,"","success");
                                }else{
                                    this.$swal.fire("Oops",result.message,"warning");
                                }
                            }
                        });
                    }
                    
                }else{
                    this.$refs.formdata.validate();
                    return false
                }
                
            }
        },
    }
</script>